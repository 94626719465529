import { getRatingIcon, mediaRatingBands } from "../utils/getRatingIcon"
import React, { useMemo, useState } from "react"
import { mediaType } from "../interfaces/MediaType"
import { getIcon } from "../utils/getMediaItemIcon"
import styled from "styled-components"
import Stack from "./Stack/Stack"
import { AnimatePresence, motion } from "framer-motion"

type IconButtonProps = {
  isActive: boolean
}

export const IconButton = styled.button<IconButtonProps>`
  background-color: ${props => (props.isActive ? "green" : "default")};
  margin-right: var(--space-s);
  color: ${props => (props.isActive ? "white" : "default")};
  text-transform: capitalize;
`

type MediaItemFilterControlsProps = {
  changeMediaType: (categoryToFilter: string) => void
  activeMediaType: string
  changeRatingFilter: (rating: number) => void
  activeRatingFilter: number
  filterBacklogItems: boolean
  changeBacklogFilter: (isShowingOnlyBacklogItems: boolean) => void
}

export const MediaItemFilterControls = ({
  changeMediaType,
  activeMediaType,
  activeRatingFilter,
  filterBacklogItems,
  changeRatingFilter,
  changeBacklogFilter,
}: MediaItemFilterControlsProps) => {
  const mediaTypeButtonDetails = useMemo(() => {
    let buttonDetailsArray: { label: string; key: string; icon: string }[] = []

    buttonDetailsArray = Object.entries(mediaType).map(k => ({
      label: k[1],
      key: k[0],
      icon: getIcon(k[0] as keyof typeof mediaType),
    }))

    return [
      ...buttonDetailsArray,
      {
        label: "All",
        key: "all",
        icon: "🥞",
      },
    ]
  }, [])
  const [mediaTypeButtons] = useState(mediaTypeButtonDetails)

  const mediaRatings = useMemo(() => {
    let buttonDetailsArray: { label: string; key: number; icon: string }[] = []

    buttonDetailsArray = Object.entries(mediaRatingBands).map(o => ({
      label: o[0],
      key: o[1],
      icon: getRatingIcon(o[1]),
    }))

    return [
      ...buttonDetailsArray,
      {
        label: "All",
        key: 99,
        icon: "🐕",
      },
    ]
  }, [])

  const handleChangeMediaType = (type: string) => {
    changeMediaType(type)
  }

  const handleChangeRating = (rating: number) => {
    changeRatingFilter(rating)
  }

  return (
    <Stack topMarginSize="m">
      <Stack topMarginSize={"s"}>
        <h2>Filter by type</h2>
        {mediaTypeButtons.map(type => (
          <IconButton
            key={type.key}
            aria-pressed={activeMediaType === type.key}
            isActive={activeMediaType === type.key}
            onClick={() => handleChangeMediaType(type.key)}
          >
            {type.label} {type.icon}
          </IconButton>
        ))}
      </Stack>
      {!filterBacklogItems && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Stack topMarginSize={"s"}>
            <h2>Filter by rating</h2>
            {mediaRatings.map(ratingLabel => (
              <IconButton
                key={ratingLabel.label}
                aria-pressed={activeRatingFilter === ratingLabel.key}
                title={`Filter by rating ${ratingLabel.key}`}
                isActive={activeRatingFilter === ratingLabel.key}
                onClick={() => handleChangeRating(ratingLabel.key)}
              >
                {ratingLabel.label} {ratingLabel.icon}
              </IconButton>
            ))}
          </Stack>
        </motion.div>
      )}
      <div>
        <label>
          <input
            type="checkbox"
            value={`${filterBacklogItems}`}
            checked={filterBacklogItems}
            onChange={() => changeBacklogFilter(!filterBacklogItems)}
          />
          &nbsp; Show only items in my backlog
        </label>
      </div>
    </Stack>
  )
}
