import Layout from "@components/layout"
import { AnimatePresence, motion } from "framer-motion"
import React, { useCallback, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { MediaJournalCard } from "../components/Cards/MediaJournalCard"
import { MediaItemFilterControls } from "../components/MediaItemFilterControls"
import { AppContext, AppContextProviderValue } from "../contexts/AppContext"
import { SET_MEDIA_ITEMS } from "../contexts/AppContext.consts"
import { UserContext, UserContextProviderValue } from "../contexts/UserContext"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { useMediaItems } from "../hooks/useMediaItems"
import { MediaItemDto } from "../types/MediaItemDto"
import { mediaRatingBands } from "../utils/getRatingIcon"
import Stack from "@components/Stack/Stack"

const GridList = styled.ul`
  display: grid;
  gap: var(--gap);
  width: 100%;
  list-style: none;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin: 0;
  @media (min-width: 992px) {
    article {
      max-width: 300px;
    }
  }
`

const MediaJournal = () => {
  const [token] = useLocalStorage<string>("ss_token", "")
  const { state, dispatch } = useContext<AppContextProviderValue>(AppContext)
  const [idHasErrorDeleting, setIdHasErrorDeleting] = useState("")
  const { mediaItems, isLoading, hasError } = useMediaItems()
  const [mediaTypeFilter, setMediaTypeFilter] = useState("all")
  const [ratingToFilter, setRatingToFilter] = useState(99)
  const [filterByBackLogItems, setFilterByBackLogItems] = useState(false)
  const [filteredMediaItems, setFilteredMediaItems] = useState<
    MediaItemDto[] | undefined
  >([])
  const { state: userState } = useContext<UserContextProviderValue>(UserContext)

  const handleDeleteItem = async (id: string) => {
    const { mediaItems } = state.mediaJournal

    if (!mediaItems) {
      return
    }

    const itemToDelete = mediaItems.find(item => item.id === id)

    if (!id) {
      return
    }

    const getMediaItemsWithDeletedRemoved = () => {
      const toDelete = new Set([id])
      return mediaItems.filter(item => !toDelete.has(item.id))
    }

    let mediaItemsWithDeletedRemoved = getMediaItemsWithDeletedRemoved()

    try {
      if (!itemToDelete) {
        throw new Error("Element not found")
      }

      setIdHasErrorDeleting("")

      dispatch({
        type: SET_MEDIA_ITEMS,
        data: mediaItemsWithDeletedRemoved,
      })

      const response = await fetch(`./api/delete-media-item?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }
    } catch (error) {
      console.log(error)
      setIdHasErrorDeleting(id)
      dispatch({
        type: SET_MEDIA_ITEMS,
        data: [...mediaItemsWithDeletedRemoved, itemToDelete],
      })
    }
  }

  const filterMediaByType = (typeToFilter: string) => {
    setMediaTypeFilter(typeToFilter)
  }

  const filterByRating = (ratingToFilter: number) => {
    setRatingToFilter(ratingToFilter)
  }

  const filterByBacklog = (isBacklogItems: boolean) => {
    console.log(isBacklogItems)
    setFilterByBackLogItems(isBacklogItems)
  }

  const ratingFilter = useCallback(
    (el: MediaItemDto, ratingToFilter: number): boolean => {
      if (!el.rating) {
        return true
      }
      if (ratingToFilter === mediaRatingBands.bad) {
        return el.rating < mediaRatingBands.good
      }
      if (ratingToFilter === mediaRatingBands.good) {
        return (
          el.rating >= mediaRatingBands.good &&
          el.rating < mediaRatingBands.great
        )
      }
      if (ratingToFilter === mediaRatingBands.great) {
        return (
          el.rating >= mediaRatingBands.great &&
          el.rating < mediaRatingBands.fire
        )
      }
      if (ratingToFilter === mediaRatingBands.fire) {
        return (
          el.rating >= mediaRatingBands.fire &&
          el.rating < mediaRatingBands.incredible
        )
      }
      if (ratingToFilter === mediaRatingBands.incredible) {
        return el.rating >= mediaRatingBands.incredible
      }

      return true
    },
    [ratingToFilter, mediaTypeFilter]
  )

  useEffect(() => {
    console.log(mediaTypeFilter, ratingToFilter)
    setFilteredMediaItems(mediaItems)

    if (mediaTypeFilter === "all") {
      setFilteredMediaItems(mediaItems)
    } else {
      console.log("Should be filtering here")
      setFilteredMediaItems(
        mediaItems?.filter(item => item.mediaType === mediaTypeFilter)
      )
    }

    if (!filterByBackLogItems) {
      if (ratingToFilter === 99) {
        setFilteredMediaItems(filtered => filtered)
      } else {
        setFilteredMediaItems(filtered =>
          filtered?.filter(el => ratingFilter(el, ratingToFilter))
        )
      }
    }

    if (filterByBackLogItems) {
      console.log("here")
      setFilteredMediaItems(filtered =>
        filtered?.filter(item => item.isOnWishList)
      )
    }
  }, [ratingToFilter, mediaTypeFilter, filterByBackLogItems])

  useEffect(() => {
    if (mediaItems) {
      setFilteredMediaItems(mediaItems)
    }
  }, [mediaItems])

  if (isLoading) {
    return (
      <Layout>
        <p>Is Loading</p>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className="container">
        <Stack topMarginSize="m">
          <h1>Reading, watching, playing</h1>
          <MediaItemFilterControls
            activeMediaType={mediaTypeFilter}
            activeRatingFilter={ratingToFilter}
            changeMediaType={filterMediaByType}
            changeRatingFilter={filterByRating}
            changeBacklogFilter={filterByBacklog}
            filterBacklogItems={filterByBackLogItems}
          ></MediaItemFilterControls>
          {hasError && <p>Error Loading</p>}

          {filteredMediaItems?.length === 0 ? (
            <p>Nothing to show</p>
          ) : (
            <p>{filteredMediaItems?.length} results</p>
          )}
          <GridList>
            <AnimatePresence>
              {filteredMediaItems?.map(item => {
                return (
                  <motion.li
                    key={item.id}
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <MediaJournalCard
                      userIsLoggedIn={userState.isLoggedIn}
                      mediaItem={item}
                      handleDeleteClick={handleDeleteItem}
                    ></MediaJournalCard>
                  </motion.li>
                  // <li key={item.id}>
                  // </li>
                )
              })}
            </AnimatePresence>
          </GridList>
          {idHasErrorDeleting ? (
            <p className="error">Error deleting {idHasErrorDeleting}</p>
          ) : null}
        </Stack>
      </div>
    </Layout>
  )
}

export default MediaJournal
