import { MediaItemDto } from "../../types/MediaItemDto"
import React from "react"
import styled from "styled-components"
import { CardBase } from "./CardBase"
import { Link } from "gatsby"
import { getRatingIcon } from "../../utils/getRatingIcon"
import { MediaItemCardBody } from "./MediaItemCardBody"

const CardHeader = styled.header`
  display: flex;
  justify-content: space-between;
  h3 {
    margin-bottom: 0;
  }
  margin-bottom: 1rem;
`

const CardFooter = styled.footer``

const CardImage = styled.img`
  aspect-ratio: 100 / 149;
  object-fit: cover;
`

type MediaJournalCardProps = {
  mediaItem: MediaItemDto
  userIsLoggedIn: boolean
  handleDeleteClick: (id: string) => void
}

export const MediaJournalCard = ({
  mediaItem,
  handleDeleteClick,
  userIsLoggedIn,
}: MediaJournalCardProps) => {
  return (
    <CardBase>
      <CardHeader>
        <h3>{mediaItem.name}</h3>
        {mediaItem.url && (
          <a
            title={`Link to website for ${mediaItem.name}`}
            href={mediaItem.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            🔗
          </a>
        )}
      </CardHeader>
      <div>
        <CardImage
          alt={`Image for ${mediaItem.name}`}
          src={mediaItem.imageUrl ? mediaItem.imageUrl : ""}
        ></CardImage>
      </div>
      <CardFooter>
        <MediaItemCardBody item={mediaItem} toggleDescription={true} />

        {userIsLoggedIn && (
          <>
            <button
              type="button"
              onClick={() => handleDeleteClick(mediaItem.id)}
            >
              Delete item
            </button>
            <Link to={`/media-form?id=${mediaItem.id}`}>Edit</Link>
          </>
        )}
      </CardFooter>
    </CardBase>
  )
}
