import styled from 'styled-components';

export const CardBase = styled.article`
    border: 1px solid var(--color-border);
    padding: calc(1rem * var(--multiplier));
    width: 100%;
    height: 100%;
    z-index: 1;
    background: white;
    box-shadow: 0px 24px 25px -10px rgba(0,0,0,0.75);
    &:hover, &:active {
        animation-name: hover-effect;
        animation-duration: 1s;
    }

    @keyframes hover-effect {
        0%   {transform: rotate(0deg);}
        33%  {transform: rotate(0.25deg);}
        66%  {transform: rotate(-0.25deg);}
        100% {transform: rotate(0deg);}
    }

    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`
